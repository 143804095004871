.logoCarousel {
    margin-bottom: 90px;
    align-items: center;
}

.marqueeLogo {
    padding: 64px;
    /* width: ; */
}

.formBody {
    /* background-color: var(--Gray-7); */
    /* padding-left: 130px;
    padding-right: 130px;
    padding-top: 91px;
    padding-bottom: 150px; */
    padding-top: 72px;
    padding-right: 74px;
}

.label {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6px;
}

.label > span {
    color: red;
}

.customInput {
    border-radius: var(--Medium, 4px);
    border: 1px solid var(--neutral-stroke-1-rest, #d1d1d1);
    border-bottom: 1px solid var(--primary-alpha);
    background: var(--neutral-background-1-rest, #fff);
    margin-bottom: 16px;
}
.sectionOneContent > h3 {
    color: var(--primary-alpha);

    /* H2/Regular */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 57.6px */
    margin-bottom: 16px;
}
.sectionOneContent > p {
    color: var(--primary-alpha);
    /* H5/Regular */
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 33.6px */
    margin-bottom: 48px;
}
.submitBtn {
    width: 111px;
    height: 48px;
    border-radius: 4px;
    background: var(--secondary-alpha, #9400ff);
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 42px;
    border: none;
}
.sectionOne {
    padding: 72px;
}
.sectionOne > img {
    width: 100%;
    /* height: 100%; */
    /* background-image: ; */
}

.sectionTwo {
padding: 64px 181px;
background-color: #f6f6f8
}


.sectionTwo > p {
    color: #0E0E52;
    /* H6/Regular */
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 27.6px */
}
.sectionThree {
    padding-left: 72px;
    padding-right: 72px;
    margin-top: 96px;
    margin-bottom: 88px;
}

.sectionThreeCard {
    background: var(--primary-beta);
    border-radius: 8px;
    min-height: 160px;
    width: 100%;
    /* 39.6px */
    padding: 16px;
    margin-bottom: 20px;
    position: relative;
}

.sectionThreeCard > h1 {
    color: var(--White);
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

/* testimonial */
.testimonial {
    max-width: 856px;
    margin: auto;
}

.testimonial > span {
    color: var(--Gray-6);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.testimonial > p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 32px;
}

.testimonialByBody {
    display: flex;
    align-items: center;
}

.testimonialByBody > img {
    height: 58px;
    width: 58px;
    border-radius: 100px;
    margin-right: 16px;
}

.testimonialByBody > div > p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0px;
    /* 15.6px */
}

.sectionFour {
    background-color: var(--Gray-2);
    padding-top: 88px;
    padding-bottom: 88px;
}

/* Section Three */
.sectionFive {
    /* margin-top: 100px; */
    /* margin-bottom: 100px; */
    /* background-image: url(./assests/section3BG.png); */
    width: 100%;
    /* height: 560px; */
}

.sectionFiveImage {
    width: 100%;
    height: 100%;
}

.sectionFiveContent {
    /* max-width: 636px; */
    background-color: black;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 180px;
    /* max-width: 526px; */
}

.sectionFiveContent > div > h3 {
    color: var(--White);
    /* H2/Regular */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 32px;
    line-height: 120%; /* 57.6px */
}
.sectionFiveContent > div > p {
    color: var(--White);
    /* Large/Regular */
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 22.8px */
}
/* @media only screen and (max-width: 1100px) {} */

@media only screen and (max-width: 1100px) {
    .sectionOne {
        padding:20px;
    }

    .formBody {
        padding: 50px 20px;
        padding-top: 0px;
    }
    .sectionTwo {
        padding: 40px 20px;
    }
    .sectionThree {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
    .testimonial {
        padding: 20px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .testimonial > p {
        font-size: 23px;
    }
    .sectionFiveContent {
        padding: 70px 20px;
    }
}
