/* section One */
.sectionOne {
    padding-left: 72px;
    padding-top: 96px;
    padding-right: 27px;

}


.laptopImg {
    width: 100%;
    height: auto;
}

.sectionOneLeft {
    max-width: 636px;
}

.sectionOneLeft>h2 {
    color: var(--Primary);
    font-family: Inter;
    font-size: 57px;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 24px;
}

.sectionOneLeft>p {
    color: var(--Primary);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 24px;
    /* 27.6px */
}

.gray6 {
    color: var(--Gray-6);
}

.sectionOneLeft>div>button {
    height: 48px;
    width: 123px;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    background: var(--secondary-alpha);
    border: none;
    /* 21.6px */
}

.readyBtn {
    border-radius: 4px;
    border: 1px solid var(--secondary-alpha);
    color: var(--secondary-alpha);
    outline: none;
    font-weight: bold;
    font-family: Inter;
    font-size: 16px;
    width: 186px;
    height: 48px;
    background-color: white;
    /* margin-left: 24px; */
}


/* section Two */
.sectionTwo {
    padding-left: 72px;
    padding-right: 72px;
    margin-top: 96px;
    margin-bottom: 88px;
}

.sectionTwoCard {
    background: var(--primary-beta);
    border-radius: 8px;
    min-height: 160px;
    width: 100%;
    /* 39.6px */
    padding: 16px;
    margin-bottom: 20px;
    position: relative;
}

.sectionTwoCard>h1 {
    color: var(--White);
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}



/* Section Three */
.sectionThree {
    /* margin-top: 100px; */
    margin-bottom: 100px;
    /* background-image: url(./assests/section3BG.png); */
    width: 100%;
    /* height: 560px; */
}

.sectionThreeImage {
    width: 100%;
    height: 100%;
}

.sectionThreeContent {
    /* max-width: 636px; */
    background-color: black;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 72px;
}

.sectionThreeContent>div>h3 {
    color: var(--White);
    /* H2/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
}

.sectionThreeContent>div>p {
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 24px;
}

.sectionThreeContent>div>button {
    height: 48px;
    width: 338px;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    border: none;
    background: var(--secondary-alpha);
}


/* Section 4 */
.sectionFour {
    margin-left: 72px;
    margin-right: 72px;
    background-color: var(--primary-beta);
    padding: 64px;
    border-radius: 20px;
    position: relative;
}

.blueSection {
    max-width: 772px;
    /* margin-left: 75px; */
}

.blueSection>h3 {
    color: var(--White);
    /* H2/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    /* 57.6px */
}

.blueSection>button {
    height: 42px;
    width: 184px;
    border-radius: 4px;
    border: 1px solid var(--White);
    background-color: var(--primary-beta);
    color: var(--White);
}


/* testimonial */
.testimonial {
    max-width: 856px;
    margin: auto;
    margin-top: 88px;
    margin-bottom: 88px;
}

.testimonial>span {
    color: var(--Gray-6);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.testimonial>p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 32px;
}

.testimonialByBody {
    display: flex;
    align-items: center;
}

.testimonialByBody>img {
    height: 58px;
    width: 58px;
    border-radius: 100px;
    margin-right: 16px;
}

.testimonialByBody>div>p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0px;
    /* 15.6px */
}


/* sectionSix */
.sectionSix {
    background: var(--tertiary-alpha);
    padding: 72px;

}

.sectionSixTop {
    max-width: 746px;
    margin-bottom: 64px;
}

.sectionSixTop>h2 {
    color: var(--White);
    /* H1/Bold */
    font-family: Inter;
    font-size: 57px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
    /* 68.4px */
}

.sectionSixTop>p {
    color: var(--White);
    /* H6/Regular */
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 27.6px */
}

.whiteCard {
    border-radius: 20px;
    background-color: var(--White);
    padding: 24px;
    position: relative;
    min-height: 240px;
    margin-bottom: 24px;
    /* Font */
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

}

.whiteCard>img {
    height: 80px;
    width: 80px;
    position: absolute;
    bottom: 24px;
    right: 24px;
}

.sectionSeven {
    max-width: 1076px;
    margin: auto;
    text-align: center;
}

.sectionSeven>img {
    height: 360px;
    margin-top: 88px;
    margin-bottom: 64px;

}

.sectionSeven>h1 {
    color: var(--primary-alpha);
    text-align: center;
    /* H1/Bold */
    font-family: Inter;
    font-size: 57px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
}

.sectionSeven>p {
    color: var(--primary-alpha);
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 27.6px */
}

.sectionEight {
    padding: 72px;
}

.sectionEightCard {
    border: 1px solid var(--tertiary-gamma);
    border-radius: 8px;
    padding: 64px;
    margin-bottom: 36px;
    height: 100%;
}

.sectionEightCard>h4 {
    color: var(--tertiary-gamma);
    /* H4/Bold */
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.sectionEightCard>p {
    color: var(--primary-alpha);

    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 27.6px */
}

.sectionEight>div>button {
    height: 48px;
    width: 123px;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    background: var(--secondary-alpha);
    border: none;

    margin-top: 88px;
    margin-bottom: 30px;
}

/* sectionNine */
.sectionNine {
    background-color: #f6f6f8;
    padding-top: 88px;
    padding-bottom: 40px;

}

.sectionNine>.testimonial {
    margin-top: 0px;
}

.sectionNineBlue {
    background-color: var(--primary-beta);
    padding: 64px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 88px;
    margin-left: 72px;
    margin-right: 72px;
}

.sectionNineBlue>img {
    /* width: 300px; */
    height: 80%;
    right: 64px;
}

.sectionNineBlue>div>button {
    width: 123px;
}

.blueSection>p {
    color: var(--White);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 32px;
    margin-bottom: 32px;
}

/* sectionTen */
.sectionTen {
    width: 100%;
}

.sectionTenImage {
    width: 100%;
    height: 100%;
}

.sectionTenContent {
    /* max-width: 636px; */
    background-color: black;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 72px;
}




.sectionTenContent>div>h3 {
    color: var(--White);
    /* H2/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
}

.sectionTenContent>div>p {
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 24px;
}

.sectionTenContent>div>button {
    height: 48px;
    width: 338px;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    border: none;
    background: var(--secondary-alpha);
}

.logoCarousel {
    margin-bottom: 90px;
    align-items: center;
}

.marqueeLogo {
    padding: 64px;
    /* width: ; */
}




/* @media only screen and (max-width: 1100px) {} */

@media only screen and (max-width: 768px) {

    /* Section 1 */
    .sectionOne {
        padding-left: 20px;
    }

    .sectionOneLeft>button {
        margin-bottom: 40px;
    }

    .sectionOneLeft>h2 {
        font-size: 45px;
    }

    /* Section 2 */
    .sectionTwo {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    /* Section 3 */
    .sectionThree {
        margin-bottom: 40px;
    }

    .sectionThreeContent {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }


    .sectionThreeContent>div>h3 {
        font-size: 36px;
    }



    .chartImg {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }

    .whiteCard {
        font-size: 36px;
    }

    /* Section 4 */
    .sectionFour {
        margin-left: 20px;
        margin-right: 20px;
        padding: 30px;
    }

    .blueSection>button {
        margin-bottom: 40px;
    }

    .blueSection {
        width: 100%;
    }

    .blueSection>h3 {
        font-size: 36px;
    }

    .sectionFour>img {
        display: none;
    }


    .testimonial {
        padding: 20px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .testimonial>p {
        font-size: 23px;
    }

    .sectionSix {
        padding: 20px;
    }

    .sectionSixTop>h2 {
        font-size: 36px;
    }

    .sectionSeven {
        padding: 20px;

    }

    .sectionSeven>h1 {
        font-size: 36px;
    }

    .sectionSeven>img {
        height: auto;
        width: 100%;
    }

    .sectionEight {
        padding: 20px;
    }

    .sectionEightCard {
        padding: 32px;
    }

    .sectionEight>div>button {
        margin-top: 30px;
    }

    .sectionNine {
        padding: 20px;
    }

    .sectionNineBlue {
        padding: 32px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .sectionNineBlue>img {
        display: none;
    }

    .sectionTenContent {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .sectionTenContent>div>h3 {
        font-size: 36px;
    }

    .sectionTen>div>h3 {
        font-size: 36px;
    }

    .fullSizeBtn {
        width: 100% !important;
    }

    .readyBtn {
        margin-top: 20px;
        margin-bottom: 20px;
    }


}