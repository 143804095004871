.nav {
    height: 48px;
    padding-left: 70px;
    padding-right: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navItem {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-left: 24px;
    margin-right: 24px;
    cursor: pointer;
}

.menuBtn {
    display: none;
}
.dropDownStyle{
    top: 24px;
    left: -130px;
}

.menuBody {
    height: 101vh;
    width: 100%;
    background-color: var(--primary-alpha);
    z-index: 100000000000;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 20px;
    transition: height 4s;
}

.menuItem {
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-left: 24px;
    margin-right: 24px;
    cursor: pointer;
    border-bottom: 1px solid;
    padding-bottom: 15px;
}

.demoBtn {
    height: 48px;
    width: 123px;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    background: var(--secondary-alpha);
    border: none;
}



.menuContainer {
    position: relative;
    display: inline-block;
  }
  
  .iconContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: -111px;
    display: none;
    z-index: 1;
  }
  
  .menuContainer:hover .dropdownMenu {
    display: block;
  }
  
  .dropdownMenu > button{
    height: 35px;
    width: 130px;
    color: var(--White);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    border-radius: 4px;
    background: var(--secondary-alpha);
    border: none;
  }


@media only screen and (max-width: 768px) {
    .nav {
        padding: 10px;
        padding-right: 25px;
    }

    .navItem {
        display: none;
    }

    .menuBtn {
        display: block;
    }
}