@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans&display=swap');

body {
  margin: 0;

}

:root {
  --Primary: #1B2149;
  --White: #FFF;
  --Secondary: #8E2DE2;
  --Gray-4: #DDDEE4;
  --Gray-6: #989BAD;
  --Gray-7: #6B6F89;
  --primary-beta: #192BC2;
  --secondary-alpha: #9400FF;
  --primary-alpha: #0E0E52;
  --tertiary-alpha: #F8047E;
  --tertiary-gamma: #B4007D;
  --Gray-2: #F6F6F8
}

/* Scroll Setting */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: var(--White);
}

::-webkit-scrollbar-thumb {
  background: var(--Gray-6);
}

table {
  border-collapse: collapse;
}

td,
th {
  padding: 0em;
}