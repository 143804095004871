.sectionOne {
    background-image: url(./assests/section1BG.jpg);
    width: 100%;
    height: 70vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 80px;
    padding-right: 80px;
}
.sectionOneContent > h3 {
    color: var(--White);
    text-align: center;
    /* H1/Regular */
    font-family: Inter;
    font-size: 57px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 68.4px */
    max-width: 1200px;
    margin: auto;
    margin-bottom: 16px;
}
.sectionOneContent > p {
    color: var(--White);
    text-align: center;

    /* H6/Regular */
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 27.6px */
    max-width: 856px;
    margin: auto;
}
.sectionTwo {
    margin: 96px 72px;
}
.sectionTwoCard {
    border-radius: 8px;
    border: 2px solid var(--primary-beta);
    padding: 16px;
    height: 100%;
    position: relative;
}
.cardTitle {
    color: var(--primary-beta);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}
.sectionTwoCard>img{
    width: 100%;
}
.cardContent>h5{
    color: var(--primary-beta);
    margin-bottom: 24px;
/* H6/Bold */
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 27.6px */
}

.cardContent>p{
    color: var(--primary-alpha);

/* Large/Regular */
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 22.8px */
}
.sectionThree {
    padding-bottom: 40px;
}



.sectionThreeBlue {
    background-color: var(--primary-beta);
    padding: 64px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 88px;
    margin-left: 72px;
    margin-right: 72px;
}

.sectionThreeBlue>img {
    /* width: 300px; */
    height: 80%;
    right: 64px;
}

.sectionThreeBlue>div>button {
    width: 123px;
}

.blueSection {
    max-width: 772px;
    /* margin-left: 75px; */
}

.blueSection>h3 {
    color: var(--White);
    /* H2/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    /* 57.6px */
}

.blueSection>button {
    height: 42px;
    width: 184px;
    border-radius: 4px;
    border: 1px solid var(--White);
    background-color: var(--primary-beta);
    color: var(--White);
}

.blueSection>p {
    color: var(--White);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 32px;
    margin-bottom: 32px;
}
.arrowBody{
    position: absolute;
    height: 48px;
    bottom: 16px;
}
.dummyDiv{
    height: 48px;
}
@media only screen and (max-width: 1100px) {
    .sectionOneContent > h3 {
        font-size: 45px;
    }
    .sectionOne {
        padding: 20px;
        height: 60vh;
    }
.sectionTwo{
    margin: 40px 20px;
}

    .sectionThreeBlue {
        padding: 32px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .sectionThreeBlue>img {
        display: none;
    }

    .blueSection>button {
        margin-bottom: 40px;
    }

    .blueSection {
        width: 100%;
    }

    .blueSection>h3 {
        font-size: 36px;
    }

    .sectionThree {
        padding: 20px;
    }

    .sectionThreeBlue {
        padding: 32px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .sectionThreeBlue>img {
        display: none;
    }
}
