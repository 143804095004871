/* sectionOne */
.sectionOne {
    width: 100%;
}

.sectionOneImage {
    width: 100%;
    /* height: 642px; */
    float: right;
}

.sectionOneContent {
    /* max-width: 636px; */
    background-color: var(--White);
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 72px;
}

table {
    border-collapse: collapse;
}

.subScribeButton{
    border-radius: 4px;
    border: 1px solid var(--secondary-alpha);
    color: var(--secondary-alpha);
    outline: none;
    font-weight: bold;
    font-family: Inter;
    font-size: 16px;
    width: 120px;
    height: 48px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sectionOneContent>div>h3 {
    color: var(--primary-alpha);
    /* H2/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
}

.sectionOneContent>div>p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 24px;
}

.priceCardWeb {
    border: 1px solid var(--primary-alpha);
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 32px;
}

.sectionTwoWeb {
    padding-left: 72px;
    padding-right: 72px;
    background: var(--Gray-2);
    padding-top: 68px;
    padding-bottom: 52px;
}

.tableBody {
    /* margin-bottom: 96px; */
    width: 100%;
}

.priceCardHeader {
    background: var(--primary-alpha);
    height: 72px;
    color: var(--White);
    /* H4/Bold */
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;
}

.cardDesc {
    /* padding: 16px 32px; */
    color: var(--Gray-7);
    text-align: center;
    /* Body/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 298px;
    /* 19.2px */
}

.priceDiv {
    text-align: center;
    /* margin-top: 35px; */
}

.priceDiv>h3 {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 0px;
}

.priceDiv>span {
    color: var(--Gray-6);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}


.comingSoonText {
    color: var(--Gray-6);
    font-family: Inter;
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 16px;
    margin-bottom: 16px;

}
.contactSalesBtn {
    border-radius: 4px;
    border: 1px solid var(--primary-alpha);
    background-color: white;
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    width: 142px;
    height: 48px;
    /* margin: auto; */
    margin-top: 16px;
    margin-bottom: 16px;

}

.rowItem {
    border-bottom: 1px solid var(--Gray-4);
    padding: 16px;
}

.headingTextRowItem {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    height: 64px;
    padding-left: 16px;
    border-bottom: 1px solid var(--Gray-4);
    /* vertical-align: middle; */
}

.detailRowItem {
    color: var(--primary-alpha);
    text-align: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border-bottom: 1px solid var(--Gray-4);
}

.mobilePricingBody {
    display: none;
    margin-bottom: 100px;
}

.pricingCard {
    border: 1px solid;
    border-radius: 8px;
    border: 1px solid var(--primary-alpha);
    padding-bottom: 48px;

}

.pricingCardHeader {
    border-top-right-radius: 6.5px;
    border-top-left-radius: 6.5px;
    background: var(--primary-alpha);
    color: var(--White);
    /* H4/Bold */
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
}

.dashsedLine {
    height: 2px;
    border-bottom: 1px dashed #989BAD;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 32px;
    margin-top: 32px;

}

.mobileChracteristics {
    color: var(--primary-alpha);
    text-align: center;
    /* H6/Bold */
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
    /* 27.6px */
}

.advisoryDiv {
    margin-top: 64px;
    border-bottom: 1px dashed var(--primary-alpha);
    padding-bottom: 8px;
    color: var(--primary-alpha);
    text-align: center;
    /* Body/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 24px;
    /* 19.2px */
}

.hoverableRow:hover {
    background-color: #e9eaee;
}

.signUpbtn {
    width: 93px;
    height: 48px;
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    border-radius: 4px;
    background: var(--secondary-alpha);
    outline: none;
    border: none;
    margin-top: 35px;
}

.marqueeBody {
    background-color: white;
    margin-top: 64px;
    margin-bottom: 88px;
}

@media only screen and (max-width: 1320px) {
    .tableBody {
        display: none;
    }

    .mobilePricingBody {
        display: block;
    }

    .cardDesc {
        padding: 16px 32px;
        width: 100%;
    }
}

.accodonDiv {
    /* max-width: 635px;
    margin: auto; */
    margin-top: 128px;
}

.addOnsHeading {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 16px;
}

.addOnsText {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .sectionOneContent {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .sectionOneContent>div>h3 {
        font-size: 36px;
    }

    .sectionOne>div>h3 {
        font-size: 36px;
    }

    .sectionTwoWeb {
        padding-left: 20px;
        padding-right: 20px;
    }
}