.body {
    background: var(--Gray-2);
}

.formBody {
    max-width: 745px;
    padding-left: 72px;
    padding-top: 96px;

}

.heading {
    font-family: Inter;
    font-size: 57px;
    font-style: normal;
    color: var(--primary-alpha);
    font-weight: 700;
    margin: 0px;
}

.subHeading {
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-alpha);
    margin-bottom: 72px;
}

.label {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6px;
}

.label>span {
    color: red;
}

.customInput {
    border-bottom: 1px solid #616161;
}

.marqueeBody {
    background-color: white;
    margin-top: 64px;
    margin-bottom: 88px;
}

.formSmall {
    color: var(--primary-alpha);
    text-align: justify;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 52px;
    margin-bottom: 0px;
}

.submitBtn {
    width: 88px;
    height: 48px;
    border-radius: 4px;
    background: var(--secondary-alpha, #9400FF);
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 42px;
    border: none;
}



/* testimonial */
.testimonial {
    max-width: 856px;
    margin: auto;
    margin-top: 88px;
    margin-bottom: 88px;
}

.testimonial>span {
    color: var(--Gray-6);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.testimonial>p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 32px;
}

.testimonialByBody {
    display: flex;
    align-items: center;
}

.testimonialByBody>img {
    height: 58px;
    width: 58px;
    border-radius: 100px;
    margin-right: 16px;
}

.testimonialByBody>div>p {
    color: var(--primary-alpha);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0px;
    /* 15.6px */
}

.sectionNine {
    background-color: #f6f6f8;
    /* padding-top: 88px; */
    padding-bottom: 40px;

}

.sectionNine>.testimonial {
    margin-top: 0px;
}

.sectionNineBlue {
    background-color: var(--primary-beta);
    padding: 64px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 88px;
    margin-left: 72px;
    margin-right: 72px;
}

.sectionNineBlue>img {
    /* width: 300px; */
    height: 80%;
    right: 64px;
}

.sectionNineBlue>div>button {
    width: 123px;
}

.blueSection {
    max-width: 772px;
    /* margin-left: 75px; */
}

.blueSection>h3 {
    color: var(--White);
    /* H2/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    /* 57.6px */
}

.blueSection>button {
    height: 42px;
    width: 184px;
    border-radius: 4px;
    border: 1px solid var(--White);
    background-color: var(--primary-beta);
    color: var(--White);
}

.blueSection>p {
    color: var(--White);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 32px;
    margin-bottom: 32px;
}

.privacyPolicy {
    color: var(--secondary-alpha);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .formBody {
        padding: 20px;
    }

    .testimonial {
        padding: 20px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .testimonial>p {
        font-size: 23px;
    }

    .sectionNineBlue {
        padding: 32px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .sectionNineBlue>img {
        display: none;
    }

    .blueSection>button {
        margin-bottom: 40px;
    }

    .blueSection {
        width: 100%;
    }

    .blueSection>h3 {
        font-size: 36px;
    }

    .sectionNine {
        padding: 20px;
    }

    .sectionNineBlue {
        padding: 32px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .sectionNineBlue>img {
        display: none;
    }
}