.nav {
    min-height: 160px;
    padding-left: 70px;
    padding-right: 72px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: var(--primary-alpha);
    padding-top: 32px;
}

.navItem {
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-left: 24px;
    margin-right: 24px;
    cursor: pointer;
    height: fit-content;
}

.demoBtn {
    width: 123px;
    height: 48px;
    border-radius: 4px;
    background: var(--secondary-alpha);
    color: white;
    outline: none;
    border: none;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 19.2px */
}

.menuBtn {
    display: none;
}

.menuBody {
    height: 101vh;
    width: 100%;
    background-color: var(--primary-alpha);
    z-index: 100000000000;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 20px;
    transition: height 4s;
}

.menuItem {
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-left: 24px;
    cursor: pointer;
    text-align: right;
}

.demoBtnFooter {
    height: 48px;
    width: 123px;
    color: var(--White);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    background: var(--secondary-alpha);
    border: none;
}

@media only screen and (max-width: 768px) {
    .nav {
        padding: 10px;
        padding-right: 25px;
    }

    .navItem {
        display: none;
    }

    .menuBtn {
        display: block;
    }

    .demoBtn {
        display: none;
    }

}