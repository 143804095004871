@keyframes rotatingLoader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotateLoader {
  animation: rotatingLoader infinite 1s linear;
}